<template>
	<main class="container-md">
		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>

		<div v-if="!loading">
			<div class="background-white w-100 mb-40 py-60 px-80 shadow-lg rounded">
				<div class="flex space-between mb-30">
					<div>
						<h1 class="mb-10">Leistir</h1>

						<p class="color-grey">Stovna leist til avtaluslag</p>
					</div>
				</div>

				<div class="mb-30">
					<label class="mb-10" for="stepListName">Heiti á leisti</label>

					<input type="text" id="stepListName" v-model="form.stepListName" @blur="$v.form.stepListName.$touch()" />

					<div class="small-text color-red mt-10 px-5" v-if="$v.form.stepListName.$invalid && $v.form.stepListName.$dirty">
						Vinaliga skriva eitt navn
					</div>
				</div>

				<div ref="steps">
					<label class="mb-10">Stig</label>

					<div class="step relative" v-for="(step, index) in form.steps" :key="`step-${index}`">
						<div class="step-group mb-10">
							<input
								type="text"
								:id="`step-name-${index}`"
								v-model.trim="step.name"
								@blur="$v.form.steps.$touch()"
								@keydown.enter="addStep($event, index)"
								@keydown="removeStepIfEmpty($event, index)"
							/>

							<div class="step-group-action" :class="{ active: hasAction(step) }" @click="toggleAction($event, step)">
								<i class="fas fa-paper-plane"></i>
							</div>

							<div class="step-group-remove" @click="removeStep(index)" v-if="form.steps.length > 1"><i class="fas fa-trash"></i></div>

							<div class="step-group-move">
								<div @click="moveUp(index)" :class="{ disabled: index == 0 }">
									<i class="fas fa-arrow-up fa-sm"></i>
								</div>

								<div @click="moveDown(index)" :class="{ disabled: index == form.steps.length - 1 }">
									<i class="fas fa-arrow-down fa-sm"></i>
								</div>
							</div>
						</div>

						<div v-if="step.action && step.action.visible">
							<select class="v-select mb-10" @change="step.action.id = $event.target.value">
								<option value="">- Ongin hending -</option>

								<option v-for="action in actions" :key="action.id" :value="action.id">{{ action.title }}</option>
							</select>
						</div>

						<div class="add-between absolute bottom flex justify-center" @click="addBetween(index)">
							<span
								class="cursor-pointer background-blue hover:background-blue-600"
								style="width: 32px; height: 32px; border-radius: 50%; display: grid; place-items: center"
							>
								<i class="fas fa-plus fa-sm color-white"></i>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="mb-30 buttons flex space-between">
				<div
					class="btn"
					:class="{ disabled: loading || $v.form.$invalid }"
					@click="createOrUpdateStepList()"
					:disabled="true"
					v-text="id ? 'Dagfør' : 'Stovna'"
				></div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
	name: 'StepListCreate',

	data() {
		return {
			id: null,

			loading: false,

			actions: [],

			form: {
				stepListName: '',

				steps: [
					{
						name: '',
						action: {
							id: null,
							visible: false,
						},
					},
				],
			},
		};
	},

	validations: {
		form: {
			stepListName: {
				required,
			},

			steps: {
				minLength: minLength(1),
				$each: {
					name: {
						required,
					},
				},
			},
		},
	},

	mounted() {
		axios
			.get(`${process.env.VUE_APP_TERMINAL_API_URL}/actions`)
			.then((response) => {
				this.actions = response.data;
			})
			.catch((error) => {
				console.log('Error fetching actions', error);
			});

		if (!this.$route.params.id) {
			return;
		}

		this.id = this.$route.params.id;
		this.loading = true;

		axios
			.get(`/steps/list/${this.id}`)
			.then((response) => {
				const stepList = response.data;

				this.form.stepListName = stepList.name;

				this.form.steps = stepList.steps;

				if (!this.form.steps.length) {
					this.form.steps = [''];

					return;
				}

				this.form.steps = stepList.steps.map((step) => {
					step.action = {
						...step.action,
						visible: false,
					};

					if (!step.action.id) {
						step.action.title = '';
						step.action.id = null;
					}

					return step;
				});
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		addBetween(index = null) {
			let steps = [...this.form.steps];

			steps.splice(index + 1, 0, { name: '', action: { id: null, visible: false } });

			this.form.steps = steps;

			this.$nextTick(() => {
				this.$refs.steps.querySelectorAll('.step-group input')[index + 1].focus();
			});
		},

		addStep(e, index = null) {
			if (e && e.ctrlKey && e.key === 'Enter') {
				return this.createOrUpdateStepList();
			}

			this.form.steps.push({ name: '', action: { id: null, visible: false } });

			// focus newly created step
			setTimeout(() => {
				if (index !== null) {
					this.$refs.steps.querySelectorAll('.step-group input')[index + 1].focus();
				}
			}, 50);
		},

		hasAction(step) {
			if (step.action && step.action.id) {
				return true;
			}

			return false;
		},

		getActionTitle(step) {
			if (!this.hasAction(step)) {
				return '';
			}

			return this.actions.find((action) => action.id == step.action.id).title;
		},

		toggleAction(e, step) {
			step.action.visible = !step.action.visible;

			if (!step.action.id) {
				return;
			}

			const target = e.target;
			const parent = target.closest('.step');

			setTimeout(() => {
				const select = parent.querySelector('select');

				if (!select) {
					return;
				}

				select.value = step.action.id;
			}, 50);
		},

		removeStep(index) {
			this.form.steps.splice(index, 1);
		},

		moveUp(index) {
			if (index <= 0) {
				return;
			}

			let steps = [...this.form.steps];

			steps.splice(index - 1, 1, this.form.steps[index]);
			steps.splice(index, 1, this.form.steps[index - 1]);

			this.form.steps = steps;
		},

		moveDown(index) {
			if (index >= this.form.steps.length - 1) {
				return;
			}

			let steps = [...this.form.steps];

			steps.splice(index + 1, 1, this.form.steps[index]);
			steps.splice(index, 1, this.form.steps[index + 1]);

			this.form.steps = steps;
		},

		removeStepIfEmpty(e, index) {
			if (this.form.steps.length < 2) {
				return;
			}

			if (this.form.steps[index].name.length > 0) {
				return;
			}

			if (e.key === 'Backspace') {
				this.form.steps = this.form.steps.filter((step, stepIndex) => stepIndex !== index);
			}
		},

		createOrUpdateStepList() {
			this.loading = true;

			let index = 0;

			for (const step of this.form.steps) {
				step.index = index++;
			}

			if (this.id) {
				axios
					.patch(`/steps/list/${this.id}`, { name: this.form.stepListName, steps: this.form.steps })
					.then(() => {
						this.$router.push({ name: 'StepLists' });
					})
					.catch((error) => {
						console.error('Error updating step list', error);
					})
					.finally(() => {
						this.loading = false;
					});

				return;
			}

			axios
				.post('/steps/list', { name: this.form.stepListName, steps: this.form.steps })
				.then(() => {
					this.$router.push({ name: 'StepLists' });
				})
				.catch((error) => {
					console.error('Error creating step list', error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.step-group {
	display: flex;
	justify-content: space-between;

	color: #383838;
	background: white;

	font-size: 1.2rem;

	border: 1px solid #e8e8e8;
	border-radius: 5px;

	box-shadow: 0 3px 4px rgba(black, 0.05);

	transition: 0.1s background-color ease, 0.1s border ease;

	&:focus-within {
		background: #f8f8f8;
	}

	.step-group-action {
		display: flex;
		justify-content: center;
		align-items: center;

		padding: 18px;

		color: #484848;

		cursor: pointer;

		border-left: 1px solid #e8e8e8;

		transition: 0.1s color ease, 0.1s background-color ease;

		&:hover {
			color: var(--color-blue);
			background-color: #f2f2f2;
		}
	}

	.step-group-remove {
		display: flex;
		justify-content: center;
		align-items: center;

		padding: 18px;

		color: #484848;

		cursor: pointer;

		border-left: 1px solid #e8e8e8;

		transition: 0.1s color ease, 0.1s background-color ease;

		&:hover {
			color: var(--color-red);
			background-color: #f2f2f2;
		}
	}

	.step-group-move {
		display: flex;
		flex-direction: column;
		justify-content: center;

		color: #686868;

		border-left: 1px solid #e8e8e8;

		div {
			display: flex;
			justify-content: center;
			align-items: center;

			padding: 4px;

			cursor: pointer;

			transition: 0.1s color ease, 0.1s background-color ease;

			&.disabled {
				opacity: 0.25;
				pointer-events: none;
			}

			&:hover {
				color: var(--color-blue);
			}

			&:first-of-type {
				border-bottom: 1px solid #e8e8e8;
			}
		}
	}

	.step-group-action {
		&.active {
			color: var(--color-blue);
		}
	}

	input {
		border: 0;
		box-shadow: none;
		padding: 18px 20px;
		background: transparent;
	}
}

.step {
	.add-between {
		position: absolute;
		bottom: -36px;
		z-index: 10;
		left: 50%;
		transform: translateY(-50%);
		opacity: 0;
		transition: 0.15s opacity ease;
	}

	&:hover {
		.add-between {
			opacity: 1;
		}
	}
}
</style>
